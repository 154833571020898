import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { createUser } from '../../redux/actions/tablet'
import { getCompanies } from '../../redux/actions/companies'
const RO = require('../../utils/language/RO.json')



const styles = (theme) => ({

  fullHeight: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#15254b'
  },

  item: {
    flex: '1'
  },

  lobbyMain: {
    backgroundImage: `url(/assets/lobby-main.jpg)`,
    backgroundSize: 'cover',
    position: 'relative',
    backgroundPosition: 'center'
  },
  carSharing: {
    backgroundImage: `url(/assets/car-sharing.jpg)`,
    backgroundSize: 'cover',
    position: 'relative',
    backgroundPosition: 'center'
  },
  info: {
    backgroundImage: `url(/assets/info.jpg)`,
    backgroundSize: 'cover',
    position: 'relative',
    backgroundPosition: 'center'
  },
  gradient: {
    backgroundImage: 'linear-gradient(rgba(21,37,75, 0.6) 20%, #15254b 97%, #15254b 99%)',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  details: {
    position: 'absolute',
    height: 200,
    bottom: '0px',
    textAlign: 'center',
    width: '100%',
    '& span:nth-of-type(1)': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundColor: '#4a9a90',
      borderRadius: '50%',
      margin: '20px',
      marginBottom: '10px',
      marginLeft: 'calc(50% - 10px)',
    },
    '& span.title': {
      color: '#ffffff',
      fontSize: '30px',
      display: 'block',
      textAlign: 'center',
      width: '100%',
      marginBottom: '10px',
    },
    '& span.details': {
      color: '#525a6d'
    }
  },
  logo: {
    position: 'absolute',
    backgroundImage: 'url(/assets/logo.png)',
    width: '300px',
    height: '300px',
    zIndex: 100,
    marginLeft: 'calc(50% - 150px)',
    marginTop: '-10px',
    backgroundSize: 'cover'
  }
})

class IntroView extends React.Component {

  goTo = (path) => {
    this.props.history.push(path)
  }

  render() {

    const { classes } = this.props
    return (
      <div className={classes.fullHeight}>
        <div className={classes.logo}></div>
        <div onClick={() => this.goTo('/tablet')} className={`${classes.item} ${classes.lobbyMain}`}>
          <div className={classes.gradient}>
            <div className={classes.details}>
              <span></span>
              <span className='title'>Vizita/Guest</span>
              <span className='details'>Welcome to VTP</span>
            </div>
          </div>
        </div>
        <div onClick={() => this.goTo('/cars')} className={`${classes.item} ${classes.carSharing}`}>
          <div className={classes.gradient}>
            <div className={classes.details}>
              <span></span>
              <span className='title'>Car Sharing</span>
              <span className='details'>Electric Cars</span>
            </div>
          </div>
        </div>
        <div onClick={() => this.goTo('/info')} className={`${classes.item} ${classes.info}`}>
          <div className={classes.gradient}>
            <div className={classes.details}>
              <span></span>
              <span className='title'>Info</span>
              <span className='details'>Useful Information</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanies: () => dispatch(getCompanies()),
    createUser: (entry) => dispatch(createUser(entry)),
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(IntroView)))
