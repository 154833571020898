import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Moment from 'react-moment'
import 'react-datepicker/dist/react-datepicker.css'
import Button from '@material-ui/core/Button'
import CONSTANTS from '../../utils/Constants'
import TablePagination from '@material-ui/core/TablePagination'
import { withStyles } from '@material-ui/core/styles'
import Navbar from '../common/Navbar'
import Notifications from '../../utils/Notifications'
import ConfirmDialog from '../common/ConfirmDialog'
import CloseIcon from '@material-ui/icons/Close'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import moment from 'moment'
import 'date-fns'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns'
import { getCompaniesNoAuth } from '../../redux/actions/companies'
import FormControl from '@material-ui/core/FormControl'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { connect } from 'react-redux'
import { getRents } from '../../redux/actions/rents'
const RO = require('../../utils/language/RO.json')

const styles = (theme) => ({
  root: {
    justifyContent: 'flex-end',
    display: 'flex',
    paddingBottom: '1.7em',
    marginRight: '1.5em',
  },
  datepicker: {
    zIndex: '100',
    marginRight: '0.3rem',
    width: '11em',
    marginLeft: '1em',
  },
  dateSelector: {
    display: 'flex',
    marginRight: 'auto',
    marginLeft: '1.5em',
    width: '14em',
  },
  actions: {
    display: 'flex',
    ['@media (max-width: 1300px)']: {
      display: 'block',
    },
  },
  logout: {
    marginRight: 'auto',
  },
  table: {
    ['@media (min-width: 1200px)']: {
      width: '100%',
    },
  },
  filterCloseButton: {
    margin: '0',
    marginLeft: '0.3rem',
    height: '1rem',
    width: '1rem',
    minWidth: '1rem',
  },
  filterCloseIcon: {
    height: '1.2rem',
  },
  filter: {
    marginLeft: '0.7rem',
    marginTop: '2em',
  },
  arrowButton: {
    marginTop: '2.5em',
    marginRight: '0.2em',
    backgroundColor: 'inherit',
    '&:hover': {
      backgroundColor: '#DADADA',
    },
    color: 'black',
    height: '1.5rem',
    width: '1.5rem',
    minWidth: '1rem',
  },
  selectableRows: {
    cursor: 'pointer',
  },
})

const columns = [
  { label: RO.rents.index, minWidth: 20 },
  { label: RO.rents.name, minWidth: 20 },
  { label: RO.rents.email, minWidth: 20 },
  { label: RO.rents.phone, minWidth: 20 },
  { label: RO.rents.company, minWidth: 20 },
  { label: RO.rents.car, minWidth: 20 },
  { label: RO.rents.rentedAt, minWidth: 20 },
  { label: RO.rents.returnedAt, minWidth: 20 },
  { label: RO.rents.duration, minWidth: 20 },
  { label: RO.rents.pin, minWidth: 20 },
]

class RentsList extends React.Component {
  initialDate

  state = {
    classes: '',
    page: 0,
    rowsPerPage: CONSTANTS.INITIAL_ROWS_PER_PAGE,
    rents: [],
    startDate: moment().startOf('day').toISOString(),
    endDate: moment().endOf('day').toISOString(),
    count: 0,
    csvData: [],
    showFilterIcon: false,
    currentRow: {},
    companies: [],
    company: '',
  }

  componentDidMount() {
    this.setInitialDate()
    this.getRents()
    this.getCompanies();
  }

  getCompanies = () => {
    this.props.getCompanies().then((resp) => {
      this.setState({ companies: [{ name: 'Toate', _id: undefined }, ...resp] })
    })
  }

  getRents = () => {
    this.props
      .getRents(
        this.state.page,
        this.state.rowsPerPage,
        this.state.startDate,
        this.state.endDate,
        this.state.company,
      )
      .then((response) => {
        let aux = []
        let totalDuration = 0;
        response[0].map((entry, index) => {
          const duration = entry.returnedAt ? moment.duration(moment(entry.takenAt).diff(moment(entry.returnedAt))) : null;
          totalDuration += duration;
          aux[index] = {
            [RO.rents.name]: `${entry.name} ${entry.surname}`,
            [RO.rents.email]: entry.email,
            [RO.rents.phone]: entry.phone,
            [RO.rents.company]: entry.company ? entry.company.name : '',
            [RO.rents.car]: entry.carId.registrationNumber,
            [RO.rents.rentedAt]: entry.takenAt,
            [RO.rents.returnedAt]: entry.returnedAt,
            [RO.rents.duration]: `${duration ? duration.humanize() : 0}`,
          }
        })

        aux[response[0].length + 1] = {
          [RO.rents.duration]: `Total duration: ${moment.duration(totalDuration).humanize()}`
        }

        this.setState({
          rents: response[0],
          count: response[1],
          csvData: aux,
        })
      })
  }

  setInitialDate = () => {
    this.initialDate = moment().startOf('day').toISOString()
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => {
      this.getRents()
    })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        rowsPerPage: parseInt(event.target.value, CONSTANTS.PARSE_INT_RADIX),
        page: 0,
      },
      () => {
        this.getRents()
      }
    )
  }


  onCloseFilter = (event) => {
    event.stopPropagation()
    this.setState(
      {
        showFilterIcon: false,
        startDate: this.initialDate,
        endDate: moment().endOf('day').toISOString(),
      },
      () => {
        this.handleChangePage(event, 0)
        this.getRents()
      }
    )
  }

  onFilterClick = () => {
    if (this.state.startDate > this.state.endDate)
      Notifications.error(RO.notifications.DATE_ERROR)
    else {
      if (!moment(this.initialDate).isSame(this.state.startDate)) {
        this.getRents()
        this.setState({ showFilterIcon: true })
      } else {
        this.setState({ showFilterIcon: false })
        this.getRents()
      }
    }
  }
  onArrowClick = (step) => {
    let startDate, endDate

    if (step === RO.NEXT) {
      startDate = moment(this.state.startDate).add(1, 'days').toISOString()
      endDate = moment(this.state.endDate).add(1, 'days').toISOString()
    } else {
      startDate = moment(this.state.startDate).subtract(1, 'days').toISOString()
      endDate = moment(this.state.endDate).subtract(1, 'days').toISOString()
    }

    if (moment(startDate).isAfter(this.initialDate))
      return Notifications.error(RO.notifications.DATE_ERROR)

    this.setState({ startDate: startDate, endDate: endDate }, () => {
      this.getRents()
      this.onFilterClick()
    })
  }

  onCloseModal = () => {
    this.setState({ currentRow: {} })
  }

  render() {
    const { classes } = this.props
    let totalDuration = 0;

    return (
      <div>
        <Navbar path={this.props.location.pathname} />
        <div className={classes.root}>
          <div className={classes.dateSelector}>
            <Button className={classes.arrowButton}>
              <KeyboardArrowLeftIcon
                onClick={() => {
                  this.onArrowClick(RO.PREV)
                }}
              />
            </Button>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container>
                <KeyboardDatePicker
                  autoOk={true}
                  maxDate={new Date()}
                  variant="inline"
                  format={RO.dateFormat}
                  margin="normal"
                  id="date-picker-inline"
                  label={RO.sort}
                  value={this.state.startDate}
                  onChange={(date) => {
                    this.setState(
                      {
                        startDate: date,
                        endDate: moment(date).endOf('day').toISOString(),
                      },
                      this.onFilterClick()
                    )
                  }}
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <Button className={classes.arrowButton}>
              <KeyboardArrowRightIcon
                onClick={() => {
                  this.onArrowClick(RO.NEXT)
                }}
              />
            </Button>
          </div>

          <FormControl
            margin="normal"
            style={{ width: 100 }}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              {RO.rents.company}
            </InputLabel>
            <Select
              className={classes.text}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={this.state.company}
              onChange={(event) => {
                this.setState({ company: event.target.value })
                this.onFilterClick()
              }}
              name="company"
            >
              {this.state.companies.length
                ? this.state.companies.map((company, index) => {
                  return (
                    <MenuItem
                      className={classes.menuItem}
                      value={company._id}
                    >
                      {company.name}
                    </MenuItem>
                  )
                })
                : null}
            </Select>
          </FormControl>

          <div className={classes.datepicker}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container>
                <KeyboardDatePicker
                  autoOk={true}
                  maxDate={new Date()}
                  variant="inline"
                  format={RO.dateFormat}
                  margin="normal"
                  id="date-picker-inline"
                  label={RO.startDate}
                  value={this.state.startDate}
                  onChange={(date) => {
                    this.setState({ startDate: date })
                  }}
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </div>

          <div className={classes.datepicker}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container>
                <KeyboardDatePicker
                  autoOk={true}
                  maxDate={new Date()}
                  variant="inline"
                  format={RO.dateFormat}
                  margin="normal"
                  id="date-picker-inline"
                  label={RO.endDate}
                  value={this.state.endDate}
                  onChange={(date) => {
                    this.setState({ endDate: date })
                  }}
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </div>

          <div>
            <Button
              className={classes.filter}
              onClick={() => this.onFilterClick()}
            >
              {RO.filter}
              {this.state.showFilterIcon === true ? (
                <Button
                  onClick={(event) => this.onCloseFilter(event)}
                  className={classes.filterCloseButton}
                >
                  <CloseIcon className={classes.filterCloseIcon} />
                </Button>
              ) : null}
            </Button>
          </div>
          <ConfirmDialog type="export" data={this.state.csvData} />
        </div>

        <div className={classes.table}>
          <TableContainer>
            <Table entriesList>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      size={'small'}
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.rents.map((entry, index) => {
                  const duration = entry.returnedAt ? moment.duration(moment(entry.takenAt).diff(moment(entry.returnedAt))) : null
                  totalDuration += duration;

                  return (
                    <TableRow
                      hover={true}
                      className={classes.selectableRows}
                    >
                      <TableCell size={'small'}>
                        {this.state.page * this.state.rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell size={'small'}>{entry.name} {entry.surname}</TableCell>
                      <TableCell size={'small'}>{entry.email}</TableCell>
                      <TableCell size={'small'}>{entry.phone}</TableCell>
                      <TableCell size={'small'}>{entry.company ? entry.company.name : ''}</TableCell>
                      <TableCell size={'small'}>{entry.carId.registrationNumber}</TableCell>
                      <TableCell size={'small'}>
                        <Moment format={CONSTANTS.DATE_FORMAT}>
                          {entry.takenAt}
                        </Moment>
                      </TableCell>
                      <TableCell size={'small'}>
                        {entry.returnedAt ? <Moment format={CONSTANTS.DATE_FORMAT}>
                          {entry.returnedAt}
                        </Moment> : null}
                      </TableCell>
                      <TableCell size={'small'}>{entry.returnedAt ? `${duration.humanize()}` : null}</TableCell>
                      <TableCell size={'small'}>{entry.pin}</TableCell>
                    </TableRow>
                  )
                })}
                <TableRow
                  hover={true}
                  className={classes.selectableRows}
                >
                  <TableCell style={{ textAlign: 'right' }} colSpan={8} size={'small'}>
                    <b>Total Duration:</b>
                  </TableCell>
                  <TableCell size='small'>
                    {moment.duration(totalDuration).humanize()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={this.state.count}
            rowsPerPageOptions={CONSTANTS.ROWS_PER_PAGE_ARRAY}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            rowsPerPage={this.state.rowsPerPage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanies: () => dispatch(getCompaniesNoAuth()),
    getRents: (page, rows, startDate, endDate, company) =>
      dispatch(getRents(page, rows, startDate, endDate, company)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RentsList))
