import React from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Notifications from '../../utils/Notifications'
import { withStyles } from '@material-ui/core/styles'
import { returnCar } from '../../redux/actions/rent'
import TabletHeader from '../common/TabletHeader'

const styles = (theme) => ({

  nextButton: {
    backgroundColor: '#214088',
    '&:hover': {
      backgroundColor: '#123ca0',
    },
    height: '60px',
    marginRight: '0px',
    marginLeft: 'auto',
    marginTop: '50px',
    width: '200px',
    float: 'right',
    fontSize: '20px'
  },

  backButton: {
    backgroundColor: '#214088',
    '&:hover': {
      backgroundColor: '#123ca0',
    },
    height: '60px',
    marginRight: '0px',
    marginLeft: 'auto',
    left: '-13%',
    marginTop: '50px',
    width: '200px',
    float: 'left',
    textAlign: 'center',
    fontSize: '20px',
    color: '#ffffff',
    lineHeight: '60px',
  },
  flexContainer: {
    display: 'flex',
  },
  gridContainer: {
    display: 'grid',
    width: '100%',
    height: '10em',
    gridTemplateAreas: `
                          'email sign'
                          'phone sign'
                        `,
    gridTemplateColumns: '1fr 250px',
    gridTemplateRows: '1fr 1fr',
  },
  email: {
    gridArea: 'email',
  },
  phone: {
    gridArea: 'phone',
  },
  sign: {
    gridArea: 'sign',
    marginTop: '1em',
    marginLeft: '3.5em',
  },
  buttonsContainer: {
    position: 'absolute',
    bottom: '1em',
    left: '2.5%',
    right: '2.5%',
  },
  buttons: {
    backgroundColor: '#214088',
    '&:hover': {
      backgroundColor: '#123ca0',
    },
    display: 'flex',
    justifyContent: 'space-between',
  },
  tabletForm: {
    top: '140px',
    left: '10%',
    width: '80%',
    position: 'absolute'
  },
  identityFields: {
    width: '10%',
    marginRight: '2%',
  },
  fullHeight: {
    height: '100%',
    backgroundColor: '#15254b',

  },
  menuItem: {
    justifyContent: 'space-evenly',
  },
  text: {
    color: '#e0e0e0 !important',
    borderColor: '#e0e0e0 !important',
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: '#e0e0e0'
    },
    '& .MuiFormLabel-root': {
      color: '#e0e0e0 !important'
    },
    height: '40px',

    // '& .MuiInput-underline': {
    //   borderBottom: '1px solid #e0e0e0 !important'
    // }
  },
  logo: {
    position: 'absolute',
    backgroundImage: 'url(/assets/logo.png)',
    width: '150px',
    height: '150px',
    zIndex: 100,
    marginLeft: 'calc(50% - 75px)',
    marginTop: '30px',
    backgroundSize: 'cover'
  },
})

class ReturnCarView extends React.Component {
  state = {
    pin: '',
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleEntries = () => {

    let entry = {
      pin: this.state.pin,
      date: new Date(),
    }

    this.setState({
      pin: '',
      show: false,
    })
    this.props.returnCar(entry, this.props.match.params.CARID).then((response) => {
      // Notifications.success('Masina a fost returnata cu success');
      this.props.history.push(`/message?roText=Masina cu numarul de inmatriculare ${response.data.registrationNumber} a fost returnata. Va rugam sa lasati cheia la receptie. Va multumim! &enText=The car with registration number ${response.data.registrationNumber} was returned. Please drop the key at the reception. Thank you!`)
    }).catch((error) => {
      Notifications.error('PIN gresit')
    })

  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.fullHeight}>
        <TabletHeader backTo={"/cars"} title={"Return a car / Returneaza Masina"} />

        <div className={classes.logo}></div>

        <div className={classes.tabletForm}>

          <TextField
            inputProps={{ className: classes.text }}
            InputLabelProps={{ className: classes.text }}
            margin="normal"
            required
            fullWidth
            id="pin"
            label={"PIN masina"}
            name="pin"
            autoComplete="pin"
            onChange={this.onChange}
            value={this.state.PIN}
          />
          <Button
            className={classes.nextButton}
            onClick={() => {
              this.handleEntries()
            }}
          >
            Returneaza
          </Button>

        </div>
        <div className={classes.buttonsContainer}>
          <div className={classes.buttons}>

          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    returnCar: (entry, carId) => dispatch(returnCar(entry, carId)),
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ReturnCarView)))
