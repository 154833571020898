import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import TabletHeader from './TabletHeader'

const styles = (theme) => ({

  nextButton: {
    backgroundColor: '#214088',
    '&:hover': {
      backgroundColor: '#123ca0',
    },
    height: '60px',
    marginRight: '0px',
    marginLeft: 'auto',
    marginTop: '50px',
    width: '200px',
    float: 'right',
    fontSize: '20px'
  },

  backButton: {
    backgroundColor: '#214088',
    '&:hover': {
      backgroundColor: '#123ca0',
    },
    height: '60px',
    marginRight: '0px',
    marginLeft: 'auto',
    left: '-13%',
    marginTop: '50px',
    width: '200px',
    float: 'left',
    textAlign: 'center',
    fontSize: '20px',
    color: '#ffffff',
    lineHeight: '60px',
  },
  flexContainer: {
    display: 'flex',
  },
  gridContainer: {
    display: 'grid',
    width: '100%',
    height: '10em',
    gridTemplateAreas: `
                          'email sign'
                          'phone sign'
                        `,
    gridTemplateColumns: '1fr 250px',
    gridTemplateRows: '1fr 1fr',
  },

  text: {
    color: '#e0e0e0 !important',
    borderColor: '#e0e0e0 !important',
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: '#e0e0e0'
    },
    '& .MuiFormLabel-root': {
      color: '#e0e0e0 !important'
    },
    height: '40px',

    // '& .MuiInput-underline': {
    //   borderBottom: '1px solid #e0e0e0 !important'
    // }
    marginTop: '50px'
  },
  logo: {
    position: 'absolute',
    backgroundImage: 'url(/assets/logo.png)',
    width: '150px',
    height: '150px',
    zIndex: 100,
    marginLeft: 'calc(50% - 75px)',
    marginTop: '30px',
    backgroundSize: 'cover'
  },
  textHolder: {
    position: 'absolute',
    padding: '0px 100px 0px 100px',
    marginTop: '500px',
    borderTop: '5px solid #1e315c',
    borderBottom: '5px solid #1e315c',
    paddingBottom: '50px'
  },
  image: {
    backgroundImage: `url(/assets/lobby-main.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%'
  },
  gradient: {
    backgroundImage: 'linear-gradient(rgba(21,37,75, 0.5) 0%, #15254b 54%, #15254b 100%)',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
})

class MessageView extends React.Component {

  render() {
    const { classes, history } = this.props
    const roTextQuery = window.location.search;
    const roText = new URLSearchParams(roTextQuery).get('roText');
    const enText = new URLSearchParams(roTextQuery).get('enText');

    return (
      <div className={classes.image} onClick={() => {
        history.push('/intro')
      }}>
        <div className={classes.gradient}>
          <TabletHeader backTo={"/intro"} backToText={"< Home/Acasa"} title={""} />

          <div className={classes.logo}></div>

          <div className={classes.textHolder} onClick={() => {
            history.push('/intro')
          }}>
            <div className={classes.text}>
              {roText}
            </div>
            <div className={classes.text}>
              {enText}
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default withRouter(connect()(withStyles(styles)(MessageView)))