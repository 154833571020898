import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { createUser } from '../../redux/actions/tablet'
import { getCars } from '../../redux/actions/cars'
import TabletHeader from '../common/TabletHeader'

const styles = (theme) => ({

  fullHeight: {
    height: '100%',
    backgroundImage: `url(/assets/car-sharing.jpg)`,
    backgroundSize: 'cover',
    position: 'relative',
  },

  item: {
    flex: '1'
  },
  logo: {
    position: 'absolute',
    backgroundImage: 'url(/assets/logo.png)',
    width: '150px',
    height: '150px',
    zIndex: 100,
    marginLeft: 'calc(50% - 75px)',
    marginTop: '30px',
    backgroundSize: 'cover'
  },
  gradient: {
    backgroundImage: 'linear-gradient(rgba(21,37,75, 0.7) 20%, #15254b 99%, #15254b 99%)',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  container: {
    marginTop: '170px',
    width: '90%',
    minHeight: '500px',
    marginLeft: 'calc(100% - 95%)'
  },
  item: {
    display: 'inline-block',
    backgroundColor: '#164050',
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '20px',
    width: '46%',
    height: '230px',
    padding: '20px',
    boxSizing: 'border-box',
    backgroundImage: 'url(/assets/car.png)',
    backgroundSize: 'cover',
    backgroundPosition: '0px -80px',
    borderRadius: '30px',
    position: 'relative',
  },
  title: {
    color: '#ffffff',
    position: 'absolute',
    bottom: '30px',
    fontSize: '27px',
    width: '90%',
    textAlign: 'center'
  },
  available: {
    position: 'absolute',
    backgroundImage: 'url(/assets/correct.png)',
    backgroundSize: 'cover',
    marginLeft: 'calc(100% - 65%)',
    marginTop: '30px',
    opacity: 0.8,
    width: '70px',
    height: '70px'
  },

  button: {
    backgroundColor: '#214088',
    '&:hover': {
      backgroundColor: '#123ca0',
    },
    height: '60px',
    marginRight: '0px',
    marginLeft: 'auto',
    marginTop: '50px',
    width: '200px',
    float: 'left',
    textAlign: 'center',
    fontSize: '20px',
    color: '#ffffff',
    lineHeight: '60px',
  },
})

class CarsView extends React.Component {

  state = {
    cars: [],
  }

  componentDidMount() {
    this.props.getCars().then(cars => {
      this.setState({ cars: cars })
    })
  }

  goTo = (path) => {
    this.props.history.push(path)
  }

  render() {
    const { classes } = this.props
    const { state } = this;
    return (
      <div className={classes.fullHeight}>
        <TabletHeader title={"Car Sharing"} backTo={"/intro"} />

        <div className={classes.gradient}>
          <div className={classes.logo}></div>
          <div className={classes.container}>

            {state.cars.map(car =>
              <div style={car.status !== 'available' ? { opacity: '0.6' } : {}} onClick={() => this.props.history.push(car.status === 'available' ? `/rent/${car._id}` : `/return/${car._id}`)} className={`${classes.item}`}>
                {car.status === 'available' ? <div className={classes.available}></div> : null}
                <span className={classes.title}>{car.registrationNumber}</span>
              </div>)
            }

          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCars: () => dispatch(getCars()),
    createUser: (entry) => dispatch(createUser(entry)),
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CarsView)))
