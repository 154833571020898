import axios from '../../utils/Axios'
import Notifications from '../../utils/Notifications'
const RO = require('../../utils/language/RO.json')

export const getRents = (page, rows, startDate, endDate, company) => (dispatch) =>
  axios
    .get(`/rents?page=${page}&rows=${rows}&start=${startDate}&end=${endDate}&company=${company}`)
    .then((resp) => ({ ...resp.data }))
    .catch((err) => {
      Notifications.error(RO.notifications.SERVER_ERROR)
      console.error(err)
    })
