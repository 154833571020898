const CONSTANTS = {
  BACKEND_URL : 'https://frontdesk.wesolvvo.com/api',
  FRONTEND_URL : 'https://frontdesk.wesolvvo.com',
  DATE_FORMAT:'YYYY/MM/DD hh:mm:s',
  CODES: {
    ACCESS: 202,
    FORBIDDEN: 403
  },
  PARSE_INT_RADIX: 10,
  ROWS_PER_PAGE_ARRAY: [50, 100, 150],
  INITIAL_ROWS_PER_PAGE: 50,
  DURATION_ARRAY: ['00:30 ore', '01:00 ore', '01:30 ore', '02:00 ore', '02:30 ore', '03:00 ore', '03:30 ore', '04.00 ore',
    '04:30 ore', '05:00 ore', '05:30 ore', '06:00 ore', '06:30 ore', '07:00 ore', '07:30 ore', '08:00 ore'],
  CANVAS: {
    HEIGHT: '150',
    WIDTH: '150'
  }
}
export default CONSTANTS


