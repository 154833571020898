import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import TabletHeader from '../common/TabletHeader'

const styles = (theme) => ({
  tabletForm: {
    display: 'flex',
    flexDirection: 'column',
    top: '300px',
    left: '10%',
    width: '80%',
    position: 'relative',
    maxHeight: '65%',
    overflow: 'auto',
    fontSize: '17px',
    color: '#ffffff'
  },
  row: {
    flexDirection: 'row',
    display: 'flex',
    borderTop: '4px solid #1e315c',
    paddingTop: '20px',
    paddingBottom: '20px'
  },

  fullHeight: {
    height: '100%',
    overflow: 'auto',
    backgroundColor: '#15254b',
  },
  logo: {
    position: 'absolute',
    backgroundImage: 'url(/assets/logo.png)',
    width: '300px',
    height: '300px',
    zIndex: 100,
    marginLeft: 'calc(50% - 150px)',
    marginTop: '-10px',
    backgroundSize: 'cover'
  },
  image: {
    flex: 1,
    borderRight: '1px solid #1e315c',
    paddingLeft: '10px',
    backgroundSize: 'cover'
  },
  text: {
    flex: 1,
    textAlign: 'right',
    paddingRight: '10px',
    '& p': {

    }
  }
})

class InfoView extends React.Component {

  render() {
    const { classes } = this.props
    return (
      <div className={classes.fullHeight}>
        <TabletHeader title={"Info"} backTo={"/intro"} />

        <div className={classes.logo}></div>

        <div className={classes.tabletForm}>
          <div className={classes.row}>
            <div className={classes.image} style={{ backgroundImage: `url(/assets/lobby-main.jpg)` }}></div>
            <div className={classes.text}>
              <p>Property Manager Vox Technology Park</p>
              <p>+40 741 240 403</p>
              <p>pm.vox@terrayochana.ro</p>
              <p>Mo-Fri: 9AM - 5PM</p>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.image} style={{ backgroundImage: `url(/assets/lounge.jpeg)` }}></div>
            <div className={classes.text}>
              <p>Coffe Shop</p>
              <p>Mo-Fri: 7AM - 4PM</p>
              <p>Sat: 8AM - 4PM</p>
              <p>Sun: 9AM - 4PM</p>
              <p>+40 727 791 498</p>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.image} style={{ backgroundImage: `url(/assets/gym.jpg)` }}></div>
            <div className={classes.text}>
              <p>Fitness</p>
              <p>Mo-Fri: 7AM - 12PM</p>
              <p>Sat: 8AM - 10PM</p>
              <p>Sun: 10AM - 8PM</p>
              <p>+40 745 355 789</p>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.image} style={{ backgroundImage: `url(/assets/bank.jpeg)`, backgroundPosition: 'center' }}></div>
            <div className={classes.text}>
              <p>Banking</p>
              <p>Mo-Fri: 9AM - 7PM</p>
              <p>Sat: Closed</p>
              <p>Sun: Closed</p>
              <p>+40 374 557 605</p>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.image} style={{ backgroundImage: `url(/assets/stairs.jpeg)` }}> </div>
            <div className={classes.text}>
              <p>Vox Technology Park</p>
              <p>Permanently hosts the largerst graffiti canvas designed by over 20 artists and volunteers, right on our staircase.</p>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.image} style={{ backgroundImage: `url(/assets/fun.jpeg)` }}></div>
            <div className={classes.text}>
              <p>Relaxation Area</p>
              <p>Take a break in the relaxation area. <br /> The zone is equipped with board games, billiards, playstation, darts and more. <br />Situated on the 7th floor, building A, you have dirrect access to the suspended terrace.  </p>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InfoView)))
