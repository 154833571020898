import axios from '../../utils/Axios'
import Notifications from '../../utils/Notifications'
const RO = require('../../utils/language/RO.json')

export const rent = (rent, carId) => (dispatch) =>
  axios
    .post(`/cars/${carId}/rent`, { rent })
    .then((resp) => ({ ...resp.data }))
    .catch((err) => {
      Notifications.error(RO.notifications.SERVER_ERROR)
    })

export const returnCar = (rent, carId) => (dispatch) =>
  axios
    .post(`/cars/${carId}/return`, { ...rent })
