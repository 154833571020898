import React from 'react'
import { withRouter } from 'react-router-dom'

function TabletHeader({ title, backTo, history, backToText }) {
    return (
        <div style={headerStyle}>
            <span style={backStyle} onClick={() => { history.push(backTo) }}>{backToText ?? "< Inapoi/Back"}</span>
            <div style={textStyle}>{title}</div>
        </div>
    )
}

const textStyle = {
    textAlign: 'center',
    width: '100%',
    padding: '10px'
}

const backStyle = {
    padding: '10px',
    backgroundColor: "#040c22",
    position: 'absolute'
}
const headerStyle = {
    display: 'flex',
    position: 'fixed',
    width: '100%',
    zIndex: '200',
    height: '40px',
    backgroundColor: '#0a1737',
    color: '#e0e0e0',
}

export default withRouter(TabletHeader)