import React from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Modal from './SignatureModal'
import CanvasDraw from 'react-canvas-draw'
import validator from 'validator'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Notifications from '../../utils/Notifications'
import { withStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import ConfirmDialog from '../common/ConfirmDialog'
import Select from '@material-ui/core/Select'
import CONSTANTS from '../../utils/Constants'
import { createUser } from '../../redux/actions/tablet'
import { getCompaniesNoAuth } from '../../redux/actions/companies'
import TabletHeader from '../common/TabletHeader'
const RO = require('../../utils/language/RO.json')

const styles = (theme) => ({

  nextButton: {
    backgroundColor: '#214088',
    '&:hover': {
      backgroundColor: '#123ca0',
    },
    height: '60px',
    marginRight: '0px',
    marginLeft: 'auto',
    marginTop: '20px',
    width: '200px',
    float: 'right',
    textAlign: 'center',
    fontSize: '20px',
    color: '#ffffff',
    lineHeight: '60px',
  },

  flexContainer: {
    display: 'flex',
  },

  email: {
    gridArea: 'email',
  },
  phone: {
    gridArea: 'phone',
  },
  sign: {
    gridArea: 'sign',
    marginTop: '1em',
    marginLeft: '3.5em',
  },
  buttonsContainer: {
    position: 'absolute',
    bottom: '1em',
    left: '2.5%',
    right: '2.5%',
  },
  buttons: {
    backgroundColor: '#214088',
    '&:hover': {
      backgroundColor: '#123ca0',
    },
    display: 'flex',
    justifyContent: 'space-between',
  },
  tabletForm: {
    position: 'relative',
    left: '10%',
    top: '140px',
    width: '80%',
  },
  identityFields: {
    width: '10%',
    marginRight: '2%',
  },
  fullHeight: {
    backgroundColor: '#15254b',
    position: 'relative',
    overflow: 'auto',
    height: '100vh',
    marginBottom: '100px'
  },
  menuItem: {
    justifyContent: 'space-evenly',
  },
  text: {
    color: '#e0e0e0 !important',
    borderColor: '#e0e0e0 !important',
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: '#e0e0e0'
    },
    '& .MuiFormLabel-root': {
      color: '#e0e0e0 !important'
    },
    height: '40px',

    // '& .MuiInput-underline': {
    //   borderBottom: '1px solid #e0e0e0 !important'
    // }
  },
  textError: {
    color: '#ff0000 !important',
    borderColor: '#ff0000 !important',
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: '#ff0000'
    },
    '& .MuiFormLabel-root': {
      color: '#ff0000 !important'
    },
    height: '40px',

    // '& .MuiInput-underline': {
    //   borderBottom: '1px solid #e0e0e0 !important'
    // }
  },
  logo: {
    position: 'absolute',
    backgroundImage: 'url(/assets/logo.png)',
    width: '150px',
    height: '150px',
    zIndex: 100,
    marginLeft: 'calc(50% - 75px)',
    marginTop: '30px',
    backgroundSize: 'cover'
  },
})

class UserView extends React.Component {
  state = {
    name: '',
    surname: '',
    email: '',
    phone: '',
    company: '',
    show: false,
    series: '',
    number: '',
    duration: '',
    observations: '',
    terms: false,
    fields: {
      errorName: false,
      errorSurname: false,
      errorEmail: false,
      errorCompany: false,
      errorPhone: false,
      errorSignature: false,
      errorSeries: false,
      errorNumber: false,
      errorDuration: false,
      errorObservations: false,
      errorTerms: false
    },
    companies: [],
    openTerms: false
  }

  componentDidMount() {
    this.props.getCompanies().then((resp) => {
      this.setState({ companies: resp })
    })
  }

  showDrawing = () => {
    this.setState({ show: true })
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleChange = (event) => {
    this.setState({ company: event.target.value })
  }

  handleEntries = () => {
    let isOk = true

    let auxFields = {
      errorName: false,
      errorSurname: false,
      errorEmail: false,
      errorCompany: false,
      errorPhone: false,
      errorSignature: false,
      errorSeries: false,
      errorNumber: false,
      errorDuraion: false,
      errorTerms: false
    }

    Object.entries(this.state.fields).forEach(([key, value]) => {
      switch (key) {
        case 'errorSurname':
          if (!this.state.surname || this.state.surname.trim() === '') {
            isOk = false
            auxFields.errorSurname = true
          }
          break
        case 'errorName':
          if (!validator.isAlpha(this.state.name)) {
            isOk = false
            auxFields.errorName = true
          }
          break
        case 'errorEmail':
          // if (!validator.isEmail(this.state.email)) {
          //   isOk = false
          //   auxFields.errorEmail = true
          // }
          break
        case 'errorCompany':
          if (validator.isEmpty(this.state.company)) {
            isOk = false
            auxFields.errorCompany = true
          }
          break
        case 'errorPhone':
          // if (!validator.isMobilePhone(this.state.phone)) {
          //   isOk = false
          //   auxFields.errorPhone = true
          // }
          break
        case 'errorSignature':
          if (this.state.show === false) {
            isOk = false
            auxFields.errorSignature = true
          }
          break
        case 'errorSeries':
          if (
            !validator.isAlpha(this.state.series) ||
            !(this.state.series.length > 0 && this.state.series.length < 3)
          ) {
            isOk = false
            auxFields.errorSeries = true
          }
          break
        case 'errorNumber':
          // if (
          //   !validator.isNumeric(this.state.number) ||
          //   this.state.number.length !== 6
          // ) {
          //   isOk = false
          //   auxFields.errorNumber = true
          // }
          break
        case 'errorDuration':
          if (validator.isEmpty(this.state.duration)) {
            isOk = false
            auxFields.errorDuration = true
          }
          break
        case 'errorTerms':
          if (this.state.terms === false) {
            isOk = false
            auxFields.errorTerms = true
          }
          break
      }
    })
    this.setState({ fields: auxFields })

    if (isOk) {
      let entry = {
        name: this.state.name,
        surname: this.state.surname,
        email: this.state.email,
        phone: this.state.phone,
        company: this.state.company,
        signature: localStorage.getItem('savedDrawing'),
        series: this.state.series,
        number: this.state.number,
        duration: this.state.duration,
        observations: this.state.observations,
        date: new Date(),
      }

      this.setState({
        name: '',
        surname: '',
        email: '',
        phone: '',
        company: '',
        series: '',
        number: '',
        duration: '',
        observations: '',
        show: false,
        terms: false
      })
      this.props.createUser(entry).then((response) => {

        // Notifications.success(RO.notifications.ENTRY_REGISTRATION);
        this.props.history.push(`/message?roText=${entry.surname}, iti multumim pentru vizita ta la Vox Technology Park! Adreseaza-te receptiei pentru indrumare! &enText=${entry.surname}, thank you for visiting Vox Technology Park! Please ask reception for guidance!`)
        // this.props.history.push('/intro')
      })
    } else {
      Notifications.error(RO.notifications.ADMIN_FAIL_REGISTRATION)
    }
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.fullHeight}>
        <TabletHeader title={"Vizita / Guest"} backTo={"/intro"} />

        <ConfirmDialog
          type={"text"}
          open={this.state.openTerms}
          handleClose={() => this.setState({ openTerms: false })}
        >
          <div>
            <b>Date cu caracter personal</b><br />
            Data processing agreement (DPA)
            Nota de informare privind prelucrarea datelor cu caracter personal

            Prelucrăm datele cu caracter personal furnizate de dumneavoastră cu ocazia inrolarii si a utilizarii serviciului de Car Sharing (utilizat in calitate de chirias in
            cadrul Cladirii VOX) sau pentru a va putea oferi acces in Cladirea VOX in calitate de Vizitator al unui chirias, in urmatoarele scopuri:

            (a) Utilizăm datele pe care sunteti de acord sa ni le furnizati pentru a comunica cu dvs. în legătură cu orice aspecte relevante legate de relatia noastra
            profesionala, de utilizarea serviciilor aferente serviciului de Car Sharing sau in legatura cu accesul oferit in cadrul Cladirii VOX. Acordul dvs. exprimat la inrolare
            / accesul in Cladirea VOX este temeiul procesarii datelor.

            (b) Putem prelucra unele dintre datele dvs. în contextul furnizării serviciilor si produselor bazate pe indeplinirea unei obligatii legale, dacă este cazul. În acest
            caz, temeiul prelucrarii este indeplinirea unei obligații legale.

            Categoriile de date prelucrate în contextul relației noastre cu dvs. sunt: numele si prenumele dvs., adresa de e-mail, numarul de telefon, codul numerit
            personal, adresa dvs, chiriasul la care are loc vizita (daca este cazul) si timpul pentru care este imprumutata masina (daca este cazul).

            Datele cu caracter personal vor fi dezvăluite numai persoanelor autorizate si exclusiv în măsura necesară pentru a servi scopurilor de procesare pentru care
            datele dvs. sunt colectate și prelucrate.

            Datele dumneavoastra vor fi stocate pe durata utilizarii aplicatiei, precum și după aceea, în conformitate cu obligațiile legale care ne revin.

            Conform Regulamentului nr. 679/2016 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera
            circulație a acestor date și de abrogare a Directivei 95/46 / CE (GDPR), beneficiaţi de urmatoarele drepturi:
            (i) dreptul de accesare - vă permite să obțineți confirmarea că datele dvs. cu caracter personal sunt prelucrate de noi și, în cazul unui răspuns afirmativ,
            detaliile relevante ale acestor activități de prelucrare;

            (ii) dreptul de rectificare – vă permite rectificarea datelor dumneavoastră cu caracter personal în cazul în care acestea sunt inexacte;

            (iii) dreptul de ștergere – vă permite să obțineți ștergerea datelor dvs. cu caracter personal în anumite cazuri (de exemplu, dacă datele nu mai sunt necesare în
            legătură cu scopurile pentru care au fost colectate sau v-ati retras consimtamantul atunci cand prelucrarea avea ca temei consimtamantul dvs. sau datele au
            fost prelucrate ilegal);

            (iv) dreptul de restricționare – vă permite să obțineți restricționarea procesării datelor dvs. cu caracter personal în anumite cazuri (de exemplu, atunci când
            contestați exactitatea datelor dvs. cu caracter personal, pentru o perioadă care ne permite să verificăm o astfel de exactitate);

            (v) dreptul de a vă opune – vă permite să vă opuneți prelucrării în continuare a datelor dvs. cu caracter personal în condițiile și limitele stabilite de lege;

            (vi) dreptul la portabilitatea datelor – vă permite să primiți datele cu caracter personal referitoare la dvs., pe care ni le-ați furnizat, într-un format structurat,
            utilizat în mod obișnuit și care poate fi citit în mod automat sau să transmiteți aceste date unui alt controlor de date.

            Pentru exercitarea acestor drepturi, ne puteţi adresa o cerere scrisă, la adresa de email office@voxtechnologypark.ro

            De asemenea, vă este recunoscut dreptul de a depune o plângere în faţa autorităţii de supraveghere in domeniul protectiei datelor cu caracter personal.
            Mentionam ca datele dumneavoastra nu vor fi supuse unui proces decizional automatizat si nu vor fi transferate in strainatate, fiind folosite doar pe teritoriul
            Romaniei.

            Pentru informații suplimentare legate de protecția datelor, va rugam să ne contactati la adresa de e-mail: office@voxtechnologypark.ro sau telefonic la +40 79
            9 000 597 și vă răspundem cu plăcere.

            <br />< br />





            We process personal data provided by you when registering and using the Car Sharing service (used as a tenant in the VOX Building) or in order to give you
            access to the VOX Building as a Visitor of a tenant, for the following purposes:

            (a) We use the data that you agree to provide to us to communicate with you in relation to any relevant matters relating to our professional relationship, the
            use of the Car Sharing service or in relation to the access provided to you within the VOX Building. Your consent expressed at the time of registration/access
            to the VOX Building is the basis for processing the data.

            (b) We may process some of your data in the context of providing services and products based on the fulfilment of a legal obligation, if applicable. In this
            case, the basis of the processing is the fulfilment of a legal obligation.

            The categories of data processed in the context of our relationship with you are: your first and last name, email address, telephone number, personal number
            code, your address, the tenant where the visit takes place (if applicable) and the time for which the car is borrowed (if applicable).

            Personal data will only be disclosed to authorized persons and only to the extent necessary to serve the processing purposes for which your data is
            collected and processed.

            Your data will be stored for the duration of the use of the application and afterwards in accordance with our legal obligations.

            According to Regulation 679/2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data
            and repealing Directive 95/46/EC (GDPR), you have the following rights:
            (i) right of access - allows you to obtain confirmation that your personal data is being processed by us and, if so, the relevant details of such processing
            activities;

            (ii) the right of rectification - allows you to rectify your personal data if it is inaccurate;

            (iii) the right of erasure - allows you to obtain erasure of your personal data in certain cases (e.g. if the data is no longer necessary for the purposes for which
            it was collected or you have withdrawn your consent where the processing was based on your consent or the data has been unlawfully processed);

            (iv) the right of restriction - allows you to obtain restrictions on the processing of your personal data in certain cases (e.g. where you dispute the accuracy of
            your personal data, for a period that allows us to verify such accuracy);

            (v) the right to object - allows you to object to further processing of your personal data under the conditions and within the limits set by law;

            (vi) the right to data portability - allows you to receive the personal data relating to you that you have provided to us in a structured, commonly used and
            machine-readable format or to transmit such data to another data controller.

            To exercise these rights, you can send us a written request to the email address office@voxtechnologypark.ro.

            You also have the right to lodge a complaint with the supervisory authority for the protection of personal data. Please note that your data will not be subject
            to an automated decision-making process and will not be transferred abroad, but will only be used in Romania.

            For further information regarding data protection, please contact us at the e-mail address: office@voxtechnologypark.ro or by phone at+40 799 000 597 and
            we will be happy to answer you.
          </div>
        </ConfirmDialog >
        <div className={classes.logo}></div>

        <div className={classes.tabletForm}>

          <TextField
            error={this.state.fields.errorSurname}
            inputProps={{ className: classes.text }}
            InputLabelProps={{ className: classes.text }}
            margin="normal"
            required
            fullWidth
            id="surname"
            label={RO.entries.surname}
            name="surname"
            autoComplete="surname"
            onChange={this.onChange}
            value={this.state.surname}
          />
          <TextField
            error={this.state.fields.errorName}
            inputProps={{ className: classes.text }}
            InputLabelProps={{ className: classes.text }}
            margin="normal"
            required
            fullWidth
            id="name"
            label={RO.entries.name}
            name="name"
            autoComplete="name"
            onChange={this.onChange}
            value={this.state.name}
          />
          <div className={classes.flexContainer}>
            <TextField
              className={classes.identityFields}
              inputProps={{ className: classes.text }}
              InputLabelProps={{ className: classes.text }}
              error={this.state.fields.errorSeries}
              margin="normal"
              required
              fullWidth
              id="name"
              label={RO.entries.series}
              name="series"
              autoComplete="name"
              onChange={this.onChange}
              value={this.state.series}
            />
            <TextField
              error={this.state.fields.errorNumber}
              inputProps={{ className: classes.text }}
              InputLabelProps={{ className: classes.text }}
              margin="normal"
              required
              fullWidth
              id="name"
              label={RO.entries.number}
              name="number"
              autoComplete="name"
              onChange={this.onChange}
              value={this.state.number}
            />
          </div>

          <FormControl
            fullWidth
            required
            margin="normal"
            className={classes.text}
            error={this.state.fields.errorDuration}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              {RO.entries.duration}
            </InputLabel>
            <Select
              className={classes.text}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={this.state.duration}
              onChange={this.onChange}
              name="duration"
            >
              {CONSTANTS.DURATION_ARRAY.map((time, index) => {
                return <MenuItem value={time}>{time}</MenuItem>
              })}
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            required
            margin="normal"
            className={classes.text}
            error={this.state.fields.errorCompany}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              {RO.entries.company}
            </InputLabel>
            <Select
              className={classes.text}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={this.state.company}
              onChange={this.onChange}
              name="company"
            >
              {this.state.companies.length
                ? this.state.companies.map((company, index) => {
                  return (
                    <MenuItem
                      className={classes.menuItem}
                      value={company.name}
                    >
                      {company.name}
                    </MenuItem>
                  )
                })
                : null}
            </Select>
          </FormControl>

          <div className={this.state.show ? classes.gridContainer : null}>
            <TextField
              className={`${classes.email}`}
              inputProps={{ className: classes.text }}
              InputLabelProps={{ className: classes.text }}
              error={this.state.fields.errorEmail}
              margin="normal"
              fullWidth
              id="email"
              label={RO.entries.email}
              name="email"
              autoComplete="email"
              onChange={this.onChange}
              value={this.state.email}
            />

            {this.state.show ? (
              <CanvasDraw
                canvasHeight={CONSTANTS.CANVAS.HEIGHT}
                canvasWidth={CONSTANTS.CANVAS.WIDTH}
                disabled={true}
                hideGrid={true}
                ref={(canvasDraw) => (this.loadableCanvas = canvasDraw)}
                saveData={localStorage.getItem('savedDrawing')}
                loadTimeOffset={2}
                className={classes.sign}
              />
            ) : null}

            <TextField
              className={classes.phone}
              inputProps={{ className: classes.text }}
              InputLabelProps={{ className: classes.text }}
              error={this.state.fields.errorPhone}
              fullWidth
              margin="normal"
              id="phone"
              label={RO.entries.phone}
              name="phone"
              autoComplete="phone"
              onChange={this.onChange}
              value={this.state.phone}
            />
          </div>

          <TextField
            margin="normal"
            inputProps={{ className: classes.text }}
            InputLabelProps={{ className: classes.text }}
            fullWidth
            id="name"
            className={classes.text}
            label={RO.entries.observations}
            name="observations"
            autoComplete="name"
            onChange={this.onChange}
            value={this.state.observations}
            style={{ marginBottom: '40px' }}
          />

          <FormControlLabel className={this.state.fields.errorTerms ? classes.textError : classes.text} control={<Checkbox
            margin="normal"
            id="name"
            className={classes.text}
            name="terms"
            autoComplete="name"

            onChange={this.onChange}
          />} label={<div> Sunt de acord cu <span onClick={() => this.setState({ openTerms: true })} className={classes.terms}>termenii si conditiile</span></div>} />

          <Modal
            showDrawing={this.showDrawing}
            error={this.state.fields.errorSignature}
          />
          <Button
            className={classes.nextButton}
            onClick={() => {
              this.handleEntries()
            }}
          >
            {RO.next}
          </Button>

        </div>
        <div className={classes.buttonsContainer}>
          <div className={classes.buttons}>

          </div>
        </div>
      </div >
    )
  }
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanies: () => dispatch(getCompaniesNoAuth()),
    createUser: (entry) => dispatch(createUser(entry)),
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UserView)))
